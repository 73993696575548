// Card.js
import React from 'react';
import './Card.css'; // Importing the CSS file for styling

function Card({ title, content, emoji }) {
  return (
    <div className="card">
      <span className="card-icon">{emoji}</span>
      <h3 className="card-title">{title}</h3>
      <p className="card-content">{content}</p>
    </div>
  );
}

export default Card;
